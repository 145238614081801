<template>
    <WaitingMobile v-if="isMobileAgent()" />
    <WaitingDesktop v-else />
</template>
  
<script>
export default {
    components: {
        WaitingDesktop: () => import('@/components/pos_goods/WaitingDesktop'),
        WaitingMobile: () => import('@/components/pos_goods/WaitingMobile'),
    },
    name: "ReceiptNormal",
    methods: {

    }
}
</script>
  
<style scoped></style>
  